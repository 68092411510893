import React, { Component } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "./index.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Nav from "../components/nav"
import Hero from "../components/hero-standard";
import Footer from "../components/footer";

class BlogPage extends Component {

  componentDidMount(){
    AOS.init({
      duration : 2000
    });

    if (typeof window !== "undefined") {
      if (window.fbq != null) {
        window.fbq('track', 'Lead');
      }
    }
  }

  componentDidUpdate() {
    AOS.refresh();
  }

  render() {
    return (
      <Layout>
        <SEO title="Staff shuttle service | Information requested" />
        <Nav />
        <div>
            <div>

              <div>

                <Hero h1="Information requested" h2="We'll be in touch" />

                <div className="article">
                  <div className='article-inner'>
                    <p style={{ textAlign: 'center', fontSize: '1.3em', color: '#3f6375', lineHeight: '150%' }}>
                      Thank you for your request.
                    </p>
                    <p style={{ textAlign: 'center', fontSize: '1.3em', color: '#3f6375', lineHeight: '150%' }}>
                      A friendly member of our team will be in touch soon with more detailed information about our packages.
                    </p>
                  </div>
                </div>
              </div>
            </div>
        </div>
        <Footer />
      </Layout>
    );
  }
};

export default BlogPage
